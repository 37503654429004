import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Letter} from '../model/Letter';
import {Reservation} from '../model/Reservation';

@Injectable({
  providedIn: 'root'
})
export class LettersService {
  USER_NAME_SESSION_ATTRIBUTE_NAME = 'ZSM1';
  USER_NAME_SESSION_ATTRIBUTE_PASSWORD = 'ZSM2';

  constructor(private http: HttpClient) {
  }

  getAllLetters() {
    const headers = this.getLoginHeaders();
    return this.http.get<Letter[]>('https://zostan-swietym-mikolajem.pl:5050/api/letters', {headers});
  }

  getAllReservations() {
    const headers = this.getLoginHeaders();
    return this.http.get<Reservation[]>('https://zostan-swietym-mikolajem.pl:5050/api/reservations', {headers});
  }

  getUnreservedLetters() {
    return this.http.get<Letter[]>('https://zostan-swietym-mikolajem.pl:5050/api/letters-not-reserved');
  }

  getLoginHeaders(): HttpHeaders {
    let headers = new HttpHeaders();
    headers = headers.append('authorization', this.createBasicAuthToken(
      sessionStorage.getItem(this.USER_NAME_SESSION_ATTRIBUTE_NAME), sessionStorage.getItem(this.USER_NAME_SESSION_ATTRIBUTE_PASSWORD)));
    return headers;
  }

  addLetter(formData: FormData) {
    let headers = new HttpHeaders();
    const password = sessionStorage.getItem(this.USER_NAME_SESSION_ATTRIBUTE_PASSWORD);
    const login = sessionStorage.getItem(this.USER_NAME_SESSION_ATTRIBUTE_NAME);
    // headers = headers.append('enctype', 'multipart/form-data');
    headers = headers.append('authorization', this.createBasicAuthToken(
      sessionStorage.getItem(this.USER_NAME_SESSION_ATTRIBUTE_NAME), sessionStorage.getItem(this.USER_NAME_SESSION_ATTRIBUTE_PASSWORD)));
    return this.http.post('https://zostan-swietym-mikolajem.pl:5050/api/letters', formData, {headers});
  }

  createBasicAuthToken(username: string, password: string) {
    return 'Basic ' + window.btoa(username + ':' + password);
  }

  reserveLetter(id: number, reservationForm: { phone: string; name: string; mail: string }) {
    return this.http.post('https://zostan-swietym-mikolajem.pl:5050/api/reserve', {
      letterId: id,
      ...reservationForm
    });
  }

  deleteLetter(id: number) {
    let headers = new HttpHeaders();
    // headers = headers.append('enctype', 'multipart/form-data');
    headers = headers.append('authorization', this.createBasicAuthToken(
      sessionStorage.getItem(this.USER_NAME_SESSION_ATTRIBUTE_NAME), sessionStorage.getItem(this.USER_NAME_SESSION_ATTRIBUTE_PASSWORD)));
    return this.http.delete('https://zostan-swietym-mikolajem.pl:5050/api/letters/' + id, {headers});
  }

  deleteReservation(id: number) {
    let headers = new HttpHeaders();
    // headers = headers.append('enctype', 'multipart/form-data');
    headers = headers.append('authorization', this.createBasicAuthToken(
      sessionStorage.getItem(this.USER_NAME_SESSION_ATTRIBUTE_NAME), sessionStorage.getItem(this.USER_NAME_SESSION_ATTRIBUTE_PASSWORD)));
    return this.http.delete('https://zostan-swietym-mikolajem.pl:5050/api/reservations/' + id, {headers});
  }
}
