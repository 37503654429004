import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {LettersService} from '../services/letters.service';
import {Letter} from '../model/Letter';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';

@Component({
  selector: 'app-first-page',
  templateUrl: './first-page.component.html',
  styleUrls: ['./first-page.component.css']
})
export class FirstPageComponent implements OnInit, AfterViewInit {
  public letters: Letter[];
  public selectedLetter: Letter;
  public showForm = false;
  public processing = false;
  public showAgreement = false;
  public dataSource: MatTableDataSource<Letter>;
  // public displayedColumns: string[] = ['id', 'name', 'age', 'title'];
  public displayedColumns: string[] = ['id', 'name', 'lastName', 'age', 'title'];

  public reservationForm = {
    name: '',
    mail: '',
    phone: '',
    agreement: false
  };
  @ViewChild('paginator', {static: false}) paginator: MatPaginator;

  constructor(private lettersService: LettersService) {
  }

  ngOnInit() {
    this.selectedLetter = null;
    this.lettersService.getUnreservedLetters().subscribe((unreservedLetters) => {
      this.dataSource = new MatTableDataSource<Letter>(unreservedLetters);
      this.dataSource.paginator = this.paginator;
      this.letters = unreservedLetters;
      if (unreservedLetters.length > 0) {
        this.selectedLetter = unreservedLetters[0];
      }

    });
    this.reservationForm = {
      name: '',
      mail: '',
      phone: '',
      agreement: false
    };
    this.showForm = false;
  }

  ngAfterViewInit() {
  }

  letterSelected(row) {
    this.selectedLetter = row;
  }

  showReservationForm() {
    this.showForm = true;
  }

  hideReservationForm() {
    this.showForm = false;
  }

  reserveLetter() {
    if (!this.reservationForm.agreement) {
      alert('Proszę zaakceptuj regulamin.');
      return;
    }
    if (!this.validateEmail(this.reservationForm.mail)) {
      alert('Proszę podaj poprawny adres email.');
      return;
    }
    if (!this.validatePhone(this.reservationForm.phone)) {
      alert('Proszę podaj poprawny numer telefonu.');
      return;
    }
    this.processing = true;
    this.lettersService.reserveLetter(this.selectedLetter.id, this.reservationForm).subscribe(res => {
        alert('Rezerwacja została dokonana :) Dziękujemy ! Proszę sprawdź swojego maila. Wysłaliśmy Ci wiadmość. ');
        this.processing = false;
        this.ngOnInit();
      },
      error => {
        alert(error.error.message);
        alert('Refreshing page');
        this.ngOnInit();
      });
  }

  public validatePhone(value) {
    const pattern = /^\d{9}$/;
    return pattern.test(value);
  }

  validateEmail(email) {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  public hideAgreement() {
    this.showAgreement = false;
  }

  public displayAgreement() {
    this.showAgreement = true;
  }
}
