import {Component, OnInit, ViewChild} from '@angular/core';
import {LettersService} from '../services/letters.service';
import {Letter} from '../model/Letter';
import {HttpClient} from '@angular/common/http';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {Reservation} from "../model/Reservation";
import {Router} from "@angular/router";

@Component({
  selector: 'app-admin-page',
  templateUrl: './admin-page.component.html',
  styleUrls: ['./admin-page.component.css']
})
export class AdminPageComponent implements OnInit {
  @ViewChild('paginator', {static: false}) paginator: MatPaginator;
  @ViewChild('paginatorReservations', {static: false}) paginatorReservations: MatPaginator;
  public allLetters: Letter[];
  public allReservations: Reservation[];
  public processing = false;
  public letterForm = new FormGroup({
    title: new FormControl('', [Validators.required, Validators.minLength(3)]),
    name: new FormControl('', [Validators.required, Validators.minLength(3)]),
    lastName: new FormControl('', [Validators.required, Validators.minLength(3)]),
    description: new FormControl('', [Validators.required, Validators.minLength(3)]),
    age: new FormControl(0, [Validators.required]),
    file: new FormControl('', [Validators.required]),
    fileSource: new FormControl('', [Validators.required])
  });
  public letterData = {
    title: '',
    name: '',
    lastName: '',
    description: '',
    age: 0
  };
  public dataSource: MatTableDataSource<Letter>;
  public dataSourceReservations: MatTableDataSource<Reservation>;
  public displayedColumns: string[] = ['id', 'name', 'lastName', 'age', 'title', 'delete'];
  public displayedColumnsReservations: string[] = ['id', 'name', 'phone', 'mail', 'letterId','letterTitle', 'delete'];

  constructor(private lettersService: LettersService, private http: HttpClient, private router: Router) {
  }

  ngOnInit() {
    this.getAllLetters();
    this.getAllReservations();
  }

  getAllLetters() {
    this.lettersService.getAllLetters().subscribe((letters) => {
      this.allLetters = letters;
      this.dataSource = new MatTableDataSource<Letter>(letters);
      this.dataSource.paginator = this.paginator;
    });
  }

  getAllReservations() {
    this.lettersService.getAllReservations().subscribe((reservations) => {
      this.allReservations = reservations;
      this.dataSourceReservations = new MatTableDataSource<Reservation>(reservations);
      this.dataSourceReservations.paginator = this.paginatorReservations;
    });
  }

  get controls() {
    return this.letterForm.controls;
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.letterForm.patchValue({
        fileSource: file
      });
    }
  }

  submit() {
    this.processing = true;
    const formData = new FormData();
    console.log('letter data', this.letterData);
    formData.append('title', this.letterData.title);
    formData.append('name', this.letterData.name);
    formData.append('lastName', this.letterData.lastName);
    formData.append('description', this.letterData.description);
    formData.append('age', '' + this.letterData.age);
    formData.append('file', this.letterForm.get('fileSource').value);
    console.log('formData', formData);
    this.lettersService.addLetter(formData).subscribe(res => {
        this.processing = false;
        alert('List dodany');
        this.getAllLetters();
        this.letterForm.reset();
        this.letterData = {
          title: '',
          name: '',
          lastName: '',
          description: '',
          age: 0
        };
      },
      error => {
        alert('Wystąpił błąd' + error.error.message);
        this.ngOnInit();
      });
  }

  deleteLetter(letter) {
    this.lettersService.deleteLetter(letter.id).subscribe(res => {
        alert('List usunięty');
        this.getAllLetters();
      },
      error => {
        alert('Wystąpił błąd' + error.error.message);
        this.ngOnInit();
      }
    );
  }

  deleteReservation(reservation) {
    this.lettersService.deleteReservation(reservation.id).subscribe(res => {
        alert('Rezerwacja usunięta');
        this.getAllReservations();
      },
      error => {
        alert('Wystąpił błąd' + error.error.message);
        this.ngOnInit();
      });
  }

  public doFilter = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }

  public doFilterReservations = (value: string) => {
    this.dataSourceReservations.filter = value.trim().toLocaleLowerCase();
  }

  logout() {
    sessionStorage.clear();
    this.router.navigate(['/']);
  }

  public getLetterTitle(letterId) {
    return this.allLetters.find(letter => letter.id === letterId).title
  }
}
